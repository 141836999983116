<template>
  <v-container fluid class="pl-12 pr-12">
    <v-layout column wrap>
      <v-row>
        <v-col>
          <p class="font-weight-black pl-3" style="font-size:32px">
            {{ $t('title.agency.applicationFormList') }}
          </p>
        </v-col>
        <v-col style="padding-right:10px">
          <!-- 登録 -->
          <v-btn
            class="font-weight-black float-right mr-4"
            align="center"
            width="70vw"
            max-width="350px"
            color="next"
            style="font-size:20px"
            dark
            @click="onClickCreate()"
            >{{ $t('button.register') }}</v-btn
          >
        </v-col>
      </v-row>
      <v-row style="width:100%" class="ma-0 pa-0">
        <v-col class="ma-0 pa-0">
          <v-data-table
            calculate-widths
            class="elevation-1"
            :headers="headers"
            :items="records"
            :options.sync="options"
            :server-items-length="total"
            :loading="isLoading"
            :loading-text="$t('description.applicationFormReading')"
            :no-data-text="$t('error.applicationFormListNotFound')"
            :footer-props="{
              itemsPerPageText: '1ページあたりの行数',
              itemsPerPageOptions: itemsPerPageOptions,
            }"
          >
            <!-- 詳細ボタン -->
            <template v-slot:[`item.detail`]="{ item }" class="pa-0">
              <v-menu offset-y left>
                <template v-slot:activator="{ on }">
                  <v-btn color="next" fab x-small dark v-on="on">
                    <v-icon>edit</v-icon>
                  </v-btn>
                </template>
                <v-list class="ma-0 pa-0">
                  <v-list-item color="error" @click="showApplicationForm(item)">
                    <v-icon>mdi-eye-outline</v-icon>
                    <v-list-item-action class="mx-0">
                      {{ $t('button.preview') }}
                    </v-list-item-action>
                  </v-list-item>
                </v-list>
                <v-list class="ma-0 pa-0">
                  <v-list-item color="error" @click="onClickEdit(item)">
                    <v-icon>edit</v-icon>
                    <v-list-item-action class="mx-0">
                      {{ $t('button.edit') }}
                    </v-list-item-action>
                  </v-list-item>
                </v-list>
                <v-list class="ma-0 pa-0">
                  <v-list-item color="error" @click="showHighLightList(item)">
                    <v-icon>edit</v-icon>
                    <v-list-item-action class="mx-0">
                      {{ $t('button.highLightList') }}
                    </v-list-item-action>
                  </v-list-item>
                </v-list>
                <v-list class="ma-0 pa-0">
                  <v-list-item
                    color="error"
                    @click="onClickEditPrintingPosition(item)"
                  >
                    <v-icon>edit</v-icon>
                    <v-list-item-action class="mx-0">
                      {{ $t('button.editPrintPosition') }}
                    </v-list-item-action>
                  </v-list-item>
                </v-list>
              </v-menu>
            </template>
            <template v-slot:[`item.confirmationMessage`]="{ item }" class="pa-0">
              <template v-if="item.confirmationMessage">
                <p
                  v-for="(message, index) in splitMessageLine(
                    item.confirmationMessage
                  )"
                  :key="index"
                  class="ma-0 pa-0"
                  align="left"
                >
                  {{ message }}
                </p>
              </template>
              <template v-else>
                -
              </template>
            </template>
          </v-data-table>
        </v-col>
      </v-row>

      <!-- ハイライト一覧表示 -->
      <HightLightListDialog
        type="list"
        :showDialog="showHighLightListDialog"
        :onEnd="onEnd"
        :onClickReorder="onClickReorder"
        :onClickPreview="onClickPreview"
        :onClickEdit="onClicHighLightEdit"
        :onClickDelete="onClickDelete"
        :onClickAddHightLight="onClickAddHightLight"
        :closeHighLightListDialog="closeHighLightListDialog"
        :coordinateManagementList="coordinateManagementList"
        :changeCoordinateManagementListData="changeCoordinateManagementListData"
        :cancelReorder="cancelReorder"
        :completeReorder="completeReorder"
        :order="order"
      />
      <!-- ハイライト削除確認 -->
      <SimpleDialog
        :showDialog="showDeleteHighLightDialog"
        :title="$t('label.deleteHighLightTitle')"
        :confirmText="$t('description.deleteHighLight')"
        :negativeButtonTitle="$t('button.cancel')"
        :positiveButtonTitle="$t('button.delete')"
        :onClickNegativeButton="cancelDelete"
        :onClickPositiveButton="deleteHighLight"
      />
      <!-- 編集ダイアログ -->
      <FormsDialog
        :showDialog="showEditFormsDialog"
        :title="$t('label.editapplicationForm')"
        :text="null"
        :subText="null"
        :negativeButtonTitle="$t('button.cancel')"
        :positiveButtonTitle="$t('button.update')"
        :onClickNegativeButton="cancelEdit"
        :onClickPositiveButton="updateApplicationForm"
        :targets="editTargets"
      />
      <ErrorDialog ref="errorDialog"></ErrorDialog>
      <CompletedDialog ref="completedDialog"></CompletedDialog>
      <!-- ハイライトプレビュー表示 -->
      <HightLightPreviewDialog
        :showDialog="showHighLightPreview"
        :closePreview="closePreview"
        :previewDescription="previewDescription"
        :previewImage="previewImage"
      />
    </v-layout>
  </v-container>
</template>

<script>
import {
  getApplicationFormList,
  deleteCoordinateManagement,
  putCoordinateManagementReOrder,
  getApplicationFormStartDateList,
  getApplicationFormMaster,
  putApplicationFormMaster,
} from '@/apis/agency/applicationForms';
import { getInsuranceTypeList } from '@/apis/agency/insuranceTypes';
import SimpleDialog from '@/components/organisms/agency/SimpleDialog';
import HightLightListDialog from '@/components/organisms/agency/HightLightListDialog';
import HightLightPreviewDialog from '@/components/organisms/agency/HightLightPreviewDialog';
import {
  ApplicationFormListTableHeader,
  ApplicationFormListDisplayDefault,
  MaxLength,
  imageScale,
} from '@/lib/const';
import moment from 'moment';
import CompletedDialog from '@/components/organisms/agency/CompletedDialog';
import ErrorDialog from '@/components/organisms/agency/ErrorDialog';
import FormsDialog from '@/components/organisms/agency/FormsDialog';
import { mapActions } from 'vuex';
import { getPdfImage, getPdfBlobToB64 } from '@/lib/commonUtil';
import { createCanvas } from 'canvas';
import { splitMessageLine } from '@/lib/commonUtil';

export default {
  name: 'ApplicationFormList',
  components: {
    SimpleDialog,
    HightLightListDialog,
    FormsDialog,
    CompletedDialog,
    ErrorDialog,
    HightLightPreviewDialog,
  },

  data() {
    return {
      headers: ApplicationFormListTableHeader,
      records: [],

      // 申込書一覧取得オプション
      options: {
        page: ApplicationFormListDisplayDefault.page,
        itemsPerPage: ApplicationFormListDisplayDefault.itemsPerPage,
        sortBy: [],
        sortDesc: [],
      },

      // 1ページあたりの表示件数の選択肢
      itemsPerPageOptions:
        ApplicationFormListDisplayDefault.itemsPerPageOptions,

      // 申込書一覧取得件数
      total: 0,

      // 帳票IDリスト
      formIdList: [],

      // 自身の帳票ID
      myFormId: null,

      // ローディング中であるかどうか
      isLoading: true,

      showHighLightListDialog: false,
      showHighLightPreview: false,
      showDeleteHighLightDialog: false,
      showEditFormsDialog: false,

      deleteHighLightDetail: null,

      coordinateManagementList: [],
      changeCoordinateManagementListData: [],

      previewDescription: null,
      previewImage: null,

      order: false,

      applicationFormId: null,

      insuranceTypeList: [],
      editTargets: [
        {
          value: null,
          targetName: 'formId',
          type: 'text',
          label: this.$t('header.applicationFormListTable.formId'),
          text: null,
          rule: `required|max:${MaxLength.FormId}|pattern:formId`,
          key: 'formId',
        },
        {
          value: null,
          targetName: 'name',
          type: 'text',
          label: this.$t('header.applicationFormListTable.name'),
          text: null,
          rule: `required|max:${MaxLength.InsuranceTypeName}`,
          key: 'name',
        },
        {
          value: null,
          targetName: 'insuranceTypeId',
          type: 'select',
          listName: 'insuranceTypeList',
          label: this.$t('header.applicationFormListTable.insuranceName'),
          text: null,
          rule: 'selectRequired',
          key: 'insuranceTypeId',
          // function: this.selectInsuranceType,
        },
        {
          value: null,
          targetName: 'confirmationMessage',
          type: 'textarea',
          label: this.$t('header.applicationFormListTable.confirmationMessage'),
          text: null,
          rule: `required|max:${MaxLength.ApplicationFormMessage}`,
          key: 'confirmationMessage',
          max: MaxLength.ApplicationFormMessage,
        },
        {
          value: null,
          targetName: 'startDate',
          type: 'date',
          label: this.$t('header.applicationFormListTable.startDate'),
          text: null,
          rule: 'required',
          key: 'startDate',
        },
      ],
      editId: null,

      images: null,
    };
  },
  async mounted() {
    await this.getRecord();

    const insuranceTypeList = await getInsuranceTypeList();
    this.$set(
      this,
      'insuranceTypeList',
      insuranceTypeList.map(insuranceType => {
        return {
          text: insuranceType.name,
          value: insuranceType.id,
        };
      })
    );

    this.$set(this, 'isLoading', false);

    // クエリパラメータに申込書の指定が存在する場合、ハイライト一覧を初期表示させる
    if (this.$route.query.applicationFormId) {
      this.$set(this, 'applicationFormId', this.$route.query.applicationFormId);
      const targetApplicationForm = this.records.find(
        applicationForm => applicationForm.id == this.applicationFormId
      );
      if (targetApplicationForm) {
        this.$set(
          this,
          'coordinateManagementList',
          targetApplicationForm.coordinateManagements
        );
        this.$set(this, 'showHighLightListDialog', true);
      }
    }
  },
  methods: {
    // ...mapActions('validation', ['setStartDates']),
    ...mapActions('ui', ['setLoading']),
    showApplicationForm(item) {
      this.$router.push({
        name: 'ApplicationFormMasterPreview',
        params: {
          applicationFormId: item.id,
        },
      });
    },
    showHighLightList(item) {
      this.$set(this, 'coordinateManagementList', item.coordinateManagements);
      this.$set(this, 'applicationFormId', item.id);

      this.$set(this, 'showHighLightListDialog', true);
    },
    onClickEditPrintingPosition(item) {
      this.$router.push({
        name: 'EditPrintingPosition',
        params: {
          applicationFormId: item.id,
        },
      });
    },
    closeHighLightListDialog() {
      this.$set(this, 'showHighLightListDialog', false);
      this.$set(this, 'images', null);
    },
    async onClickPreview(item) {
      if (!this.images) {
        // 申込書取得
        const applicationFormPdf = await getApplicationFormMaster(
          this.applicationFormId
        );
        const b64 = await getPdfBlobToB64(applicationFormPdf);

        this.images = await getPdfImage(b64);
      }
      let img = await this.getImage(this.images[item.pdfPage]);

      // ハイライト位置を特定
      const x = item.xCoordinate * imageScale;
      const y = item.yCoordinate * imageScale;
      const dx = item.width * imageScale;
      const dy = item.height * imageScale;

      const canvas = createCanvas(dx, dy);
      const canvasContext = canvas.getContext('2d');
      // ハイライト位置のみトリミング
      canvasContext.drawImage(img, x, y, dx, dy, 0, 0, dx, dy);

      this.$set(this, 'previewDescription', item.description);
      this.$set(this, 'previewImage', canvas.toDataURL());
      this.$set(this, 'showHighLightPreview', true);
    },
    closePreview() {
      this.$set(this, 'showHighLightPreview', false);
      this.$set(this, 'previewDescription', null);
      this.$set(this, 'previewImage', null);
    },
    onClickDelete(item) {
      this.$set(this, 'showDeleteHighLightDialog', true);
      this.$set(this, 'deleteHighLightDetail', item);
    },
    async deleteHighLight() {
      // 座標管理マスタ削除
      const result = await deleteCoordinateManagement(
        this.applicationFormId,
        this.deleteHighLightDetail.id
      ).catch(err => {
        console.log(err);
      });

      // 一覧の再生成
      if (result) {
        await this.getRecord();
        this.$set(this, 'coordinateManagementList', result);
      }

      this.$set(this, 'showDeleteHighLightDialog', false);
    },
    cancelDelete() {
      this.$set(this, 'showDeleteHighLightDialog', false);
    },
    onClicHighLightEdit(item) {
      this.$router.push({
        name: 'EditCoordinateManagement',
        params: {
          applicationFormId: this.applicationFormId,
          coordinateManagementId: item.id,
        },
      });
    },
    // ソート動作が終わった時（endイベント）
    async onEnd(e) {
      // 配列のINDEX修正
      await this.moveAt(
        this.changeCoordinateManagementListData,
        e.oldIndex,
        e.newIndex
      );
      this.pageSort();
    },
    onClickReorder() {
      this.$set(this, 'order', true);
      this.$set(
        this,
        'changeCoordinateManagementListData',
        JSON.parse(JSON.stringify(this.coordinateManagementList))
      );
    },
    cancelReorder() {
      this.$set(this, 'order', false);
    },
    async completeReorder() {
      const changeCoordinateManagementListData = this.changeCoordinateManagementListData.map(
        changeCoordinateManagement => {
          return {
            id: changeCoordinateManagement.id,
            screenPage: changeCoordinateManagement.screenPage,
          };
        }
      );
      await putCoordinateManagementReOrder(
        this.applicationFormId,
        changeCoordinateManagementListData
      );
      this.$set(
        this,
        'coordinateManagementList',
        JSON.parse(JSON.stringify(this.changeCoordinateManagementListData))
      );
      this.getRecord();
      this.$set(this, 'order', false);
    },
    onClickAddHightLight() {
      this.$router.push({
        name: 'AddCoordinateManagement',
        params: {
          applicationFormId: this.applicationFormId,
        },
      });
    },
    onClickCreate() {
      this.$router.push({
        path: '/add_application_form',
      });
    },
    onClickEdit(item) {
      this.$set(this, 'editId', item.id);
      this.$set(this, 'myFormId', item.formId);
      // this.getStartDate(item.insuranceTypeId);

      this.editTargets.map(editTarget => {
        editTarget.value = item[editTarget.targetName];
        // リストの取得
        if (editTarget.listName) editTarget.list = this[editTarget.listName];
        if (editTarget.type === 'date')
          editTarget.value = editTarget.value.replace(/\//g, '-');

        return editTarget;
      });

      this.$set(this, 'showEditFormsDialog', true);
    },
    cancelEdit() {
      this.$set(this, 'showEditFormsDialog', false);
    },
    async updateApplicationForm(item) {
      // 帳票IDの重複チェック
      if (this.formIdList.includes(item.formId) && item.formId !== this.myFormId) {
        this.$refs.errorDialog.open(
          this.$t('title.agency.updateFormIdError'),
          this.$t('error.formId', { formId: item.formId })
        );
        return;
      }

      const result = await putApplicationFormMaster(item, this.editId).catch(
        err => {
          console.log(err);
        }
      );

      if (result) {
        this.$refs.completedDialog.open(
          this.$t('title.agency.updateResult'),
          this.$t('success.updated')
        );
        await this.getRecord();
      } else {
        this.$refs.errorDialog.open(
          this.$t('title.agency.updateResult'),
          this.$t('error.updateFailed')
        );
      }
      this.$set(this, 'showEditFormsDialog', false);
    },
    moveAt(array, index, at) {
      if (index === at || index > array.length - 1 || at > array.length - 1) {
        return array;
      }
      const value = array[index];
      const tail = array.slice(index + 1);
      array.splice(index);
      Array.prototype.push.apply(array, tail);
      array.splice(at, 0, value);
      return array;
    },
    pageSort() {
      this.changeCoordinateManagementListData.map(
        (coordinateManagement, index) => {
          coordinateManagement.screenPage = index + 1;
          return coordinateManagement;
        }
      );
    },
    async getRecord() {
      const applicationFormList = await getApplicationFormList();
      let formIdList = [];
      this.$set(
        this,
        'records',
        applicationFormList.map(applicationForm => {
          let latestUploadDate = applicationForm.updatedAt;
          formIdList.push(applicationForm.formId);
          const coordinateManagements = applicationForm.coordinateManagements;
          for (let i = 0; i < coordinateManagements.length; i++) {
            latestUploadDate =
              latestUploadDate < coordinateManagements[i].updatedAt
                ? coordinateManagements[i].updatedAt
                : latestUploadDate;
          }
          applicationForm.latestUploadDate = moment(latestUploadDate).format(
            'YYYY/MM/DD'
          );
          applicationForm.insuranceName = applicationForm.insuranceType.name;
          applicationForm.startDate = moment(applicationForm.startDate).format(
            'YYYY/MM/DD'
          );
          return applicationForm;
        })
      );
      this.$set(this, 'formIdList', formIdList);
      this.$set(this, 'total', applicationFormList.count);
    },
    // NOTE: UAT128対応、重複条件改善予定
    // selectInsuranceType(item) {
    //   this.getStartDate(item.value);
    // },
    // async getStartDate(insuranceTypeId) {
    //   this.setLoading(true);
    //   const startDateList = await getApplicationFormStartDateList(
    //     insuranceTypeId
    //   );
    //   let validateStartDates = [];
    //   for (let i = 0; i < startDateList.length; i++) {
    //     if (this.editId !== startDateList[i].id)
    //       validateStartDates.push(
    //         moment(startDateList[i].startDate).format('YYYY-MM-DD')
    //       );
    //   }

    //   this.setStartDates(validateStartDates);
    //   this.setLoading(false);
    // },
    getImage(src) {
      return new Promise((resolve, reject) => {
        const image = new Image();
        image.onload = () => resolve(image);
        image.onerror = e => reject(e);
        image.src = src;
      });
    },
    splitMessageLine(message) {
      return splitMessageLine(message);
    },
  },
  computed: {},
};
</script>
<style>
.v_data_table_fill_width {
  width: 100%;
}
thead.v-data-table-header {
  background-color: #ddebf7;
}
thead.v-data-table-header > tr > th {
  font-size: 14px !important;
  text-align: center !important;
  padding: 0;
  white-space: pre-wrap;
}
.v_footer_justify_evenly {
  justify-content: space-evenly;
}
.v-data-table-header__icon {
  /* テーブルのソートアイコンを常に表示 */
  opacity: 1 !important;
  color: rgba(0, 0, 0, 0.2) !important;
}
th.active > .v-data-table-header__icon {
  color: rgba(0, 0, 0, 1) !important;
}
.v-data-table td {
  word-break: break-all;
}
/* 1ページあたりの行数を IE 11 でも表示させる */
.v-data-footer__select .v-select {
  width: 54px !important;
}
.v-data-table {
  overflow: hidden;
}
</style>
